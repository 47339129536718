(function() {
    'use strict';

    angular.module('ionicVideoRecommendations', [
        'ionicAlert',
        'ionicData',
        'ionicLogin',
        'ionicRequest',
        'uabDefaultImage',
        'uabDefaultVariable'
    ]);
})();
(function() {
    'use strict';

    angular.module('ionicVideoRecommendations').service(
        'ArticleRecommendationListService',
        ArticleRecommendationListService
    );

    ArticleRecommendationListService.$inject = [
        'DefaultVariableService',
        'ListManagementService',
        'RequestManagementService'
    ];

    function ArticleRecommendationListService(
        DefaultVariableService,
        ListManagementService,
        RequestManagementService
    ) {
        var ArticleRecommendationListService = this;

        ArticleRecommendationListService.getListManager = getListManager;
        function getListManager() {
            return ListManager;
        }

        ArticleRecommendationListService.loadArticles = loadArticles;
        function loadArticles(options) {
            options = RequestManagementService.getRequest(options);

            options = RequestManagementService.setModel(options, 'articles');
            options = RequestManagementService.setAction(options, 'recommended');

            options = RequestManagementService.setParams(
                options,
                [
                    'Images'
                ]
            );

            return ListManager.getMore(options);
        }

        ArticleRecommendationListService.reset = reset;
        function reset() {
            ListManager.reset();
        }

        var ListManager = ListManagementService.getListManager();

        ArticleRecommendationListService.reset();

        return ArticleRecommendationListService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicVideoRecommendations').service(
        'VideoRecommendationListService',
        VideoRecommendationListService
    );

    VideoRecommendationListService.$inject = [
        'DefaultVariableService',
        'ListManagementService',
        'RequestManagementService'
    ];

    function VideoRecommendationListService(
        DefaultVariableService,
        ListManagementService,
        RequestManagementService
    ) {
        var VideoRecommendationListService = this;

        VideoRecommendationListService.loadVideos = loadVideos;
        function loadVideos(options) {
            options = RequestManagementService.getRequest(options);

            options = RequestManagementService.setModel(options, 'videos');
            options = RequestManagementService.setAction(options, 'recommended');

            options = RequestManagementService.setParams(
                options,
                [
                    'Images'
                ]
            );

            return ListManager.getMore(options);
        }

        VideoRecommendationListService.reset = reset;
        function reset() {
            ListManager.reset();
        }
        
        var ListManager = ListManagementService.getListManager();

        VideoRecommendationListService.reset();

        return VideoRecommendationListService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicVideoRecommendations').controller(
        'RecommendationListController',
        RecommendationListController
    );

    RecommendationListController.$inject = [
        'ArticleRecommendationListService',
        'DefaultImageService',
        'DefaultVariableService',
        'VideoRecommendationListService',
        'LoginService',
        'RedirectService',
        'RequestManagementService',
        '$scope'
    ];

    function RecommendationListController(
        ArticleRecommendationListService,
        DefaultImageService,
        DefaultVariableService,
        VideoRecommendationListService,
        LoginService,
        RedirectService,
        RequestManagementService,
        $scope
    ) {
        var RecommendationListController = this;

        $scope.$watch(
            'options',
            function(options) {
                RecommendationListController.options = RequestManagementService.getRequest(
                    options
                );
            }
        );

        $scope.$watch(
            'showExpandAfter',
            function(showExpandAfter) {
                RecommendationListController.showExpandAfter = DefaultVariableService.getInteger(
                    showExpandAfter,
                    4
                );
            }
        );


        $scope.$watch(
            'video',
            function(video) {
                video = DefaultVariableService.getObject(
                    video
                );

                if (video.id !== 0) {
                    RecommendationListController.options = RequestManagementService.setParams(
                        RecommendationListController.options,
                        [
                            'id=' + video.id
                        ]
                    );

                    RecommendationListController.loadRecommendations();
                }
            }
        );

        RecommendationListController.clear = clear;
        function clear() {
            ArticleRecommendationListService.reset();
            VideoRecommendationListService.reset();
            RecommendationListController.reset();
        }

        RecommendationListController.getImage = getImage;
        function getImage(object) {
            if (RecommendationListController.model === 'articles') {
                return DefaultImageService.getArticleImage(object);
            } else {
                return DefaultImageService.getVideoImage(object);
            }
        }

        RecommendationListController.loadRecommendations = loadRecommendations;
        function loadRecommendations() {
            RecommendationListController.isLoadingRecommendations = true;

            var promise = false;
            if (RecommendationListController.model === 'articles') {
                promise = ArticleRecommendationListService.loadArticles;
            } else {
                promise = VideoRecommendationListService.loadVideos;
            }

            return promise(
                RecommendationListController.options
            ).then(
                function(data) {
                    if (data) {
                        RecommendationListController.objects = DefaultVariableService.get(
                            data[RecommendationListController.model],
                            RecommendationListController.objects
                        );
                    }

                    RecommendationListController.canLoadMore = DefaultVariableService.get(
                        data.canLoadMore,
                        false
                    );

                    return RecommendationListController.objects;
                }
            ).finally(
                function() {
                    RecommendationListController.isLoadingVideos = false;
                }
            );
        }

        RecommendationListController.selectRecommendation = selectRecommendation;
        function selectRecommendation(object) {
            var key = 'videoId';
            if (RecommendationListController.model === 'articles') {
                key = 'articleId';
            }

            var params = {};
            params[key] = object.id;

            RedirectService.goTo(
                RecommendationListController.sref,
                params
            );
        }

        RecommendationListController.toggleExpand = toggleExpand;
        function toggleExpand() {
            RecommendationListController.showExpand = DefaultVariableService.toggle(
                RecommendationListController.showExpand,
                false
            );
        }

        RecommendationListController.toggleCollapse = toggleCollapse;
        function toggleCollapse() {
            RecommendationListController.isCollapsed = DefaultVariableService.toggle(
                RecommendationListController.isCollapsed,
                true
            );
        }

        RecommendationListController.reset = reset;
        function reset() {
            RecommendationListController.canLoadMore = true;

            RecommendationListController.collapseText = DefaultVariableService.get(
                RecommendationListController.collapseText,
                'COLLAPSE'
            );

            RecommendationListController.expandText = DefaultVariableService.get(
                RecommendationListController.expandText,
                'EXPAND'
            );

            RecommendationListController.isCollapsed = true;

            RecommendationListController.isLoadingVideos = true;

            RecommendationListController.model = DefaultVariableService.getString(
                RecommendationListController.model,
                'videos'
            );

            RecommendationListController.objects = [];

            RecommendationListController.showExpand = false;

            RecommendationListController.sref = DefaultVariableService.get(
                RecommendationListController.sref,
                'app.video'
            );

            RecommendationListController.title = DefaultVariableService.get(
                RecommendationListController.title,
                false
            );
        }

        RecommendationListController.init = init;
        function init() {
            RecommendationListController.reset();
        }

        LoginService.register(RecommendationListController);
    }
})();
(function() {
    'use strict';

    angular.module('ionicVideoRecommendations').directive(
        'ionicRecommendationList',
        ionicRecommendationList
    );

    function ionicRecommendationList() {
        return {
            bindToController: {
                collapseText: '@',
                expandText:   '@',
                model:        '@',
                sref:         '@',
                title:        '@'
            },
            controller:   'RecommendationListController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope: {
                onSelect:        '=',
                options:         '=',
                showExpandAfter: '=',
                video:           '='
            },
            template:'<ion-list data-ng-if="ctrl.objects.length !== 0"><div class="row item bar bar-stable buttons" data-ng-click="ctrl.toggleCollapse()"><h1 class="title">{{ ctrl.title }}</h1><button class="button button-icon">{{ ctrl.isCollapsed ? \'SHOW\' : \'HIDE\' }} <i class="icon" data-ng-class="ctrl.isCollapsed ? \'ion-arrow-expand\' : \'ion-arrow-shrink\'"></i></button></div><div data-ng-if="!ctrl.isCollapsed"><div data-ng-if="ctrl.showExpand" class="item item-divider" data-ng-click="ctrl.toggleExpand()"><i class="icon ion-minus-round"></i> {{ ctrl.collapseText }}</div><div class="row" data-ng-repeat="object in ctrl.objects" data-ng-if="$index % 2 === 0 && (ctrl.showExpand || $index / 2 <= ctrl.showExpandAfter - 1)" data-ng-init="objectListIndex = $index"><div class="card col" data-ng-click="ctrl.selectRecommendation(ctrl.objects[objectListIndex + i])" data-ng-repeat="i in [0,1]" data-ng-if="(objectListIndex + i) < ctrl.objects.length"><div class="item item-divider">{{ ctrl.objects[objectListIndex + i].title }}</div><div class="item item-text-wrap"><img class="full-image image-card-image" data-ng-src="{{ ctrl.getImage(ctrl.objects[objectListIndex + i]) }}"></div></div></div><div data-ng-if="!ctrl.showExpand && ctrl.objects.length !== 0" class="item item-divider" data-ng-click="ctrl.toggleExpand()"><i class="icon ion-plus-round"></i> {{ ctrl.expandText }}</div></div><ionic-infinite-scroll data-ng-if="ctrl.showExpand && ctrl.canLoadMore && !ctrl.isLoadingRecommendations" can-load-more="ctrl.canLoadMore" immediate-check="false" is-loading="ctrl.isLoadingRecommendations" load-more="ctrl.loadVideos()"></ionic-infinite-scroll></ion-list>'
        };
    }
})();